import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Tile, { IShopTile } from "./Tile"

export interface IShopMediumTile extends IShopTile {
  title: string
  bulletItems: undefined
}

const MediumTileContent = styled.div`
  display: flex;
  height: 100%;
  padding: 20px;
  > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
  .tile--title {
    font-size: 25px;
    font-weight: bold;
    height: 100%;
    flex: 1;
    line-height: 1.3;
    margin-top: 0;
  }

  @media (max-width: 1140px) {
    > * {
      justify-content: center;
    }
    .tile--title {
      flex: 0;
      margin-bottom: 25px;
      line-height: 1.3;
      margin-top: 0;
    }
  }
`

const MediumTile = (props: IShopMediumTile) => (
  <Tile {...props} width="50%">
    <MediumTileContent>
      <div>
        <h2 className="tile--title">{props.title}</h2>
        <div className="tile--button">
          <Link to={props.button.target}>
            <Button size="regular" theme="primary">
              {props.button.label}
            </Button>
          </Link>
        </div>
      </div>
      <div className="tile--image">
        {props.image && (
          <GatsbyImage
            image={getGatsbyImage(props.image)}
            alt={props.image.alternativeText || ""}
            objectFit="contain"
          />
        )}
      </div>
    </MediumTileContent>
  </Tile>
)

export default MediumTile
