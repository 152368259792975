import useExitIntent from "components/ExitIntent/useExitIntent"
import { Button } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import Tile, { IShopTile } from "./Tile"

const NewsletterTileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  > .tile--title {
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 0;
  }
  > .tile--error {
    margin-bottom: 10px;
    color: #fff;
    background-color: #f00;
    padding: 4px 6px;
  }
  > .tile--form {
    display: flex;
    > input {
      margin-right: 10px;
      border-radius: 50px;
      border: none;
      padding: 0 20px;
      font-size: 18px;
      font-family: "Libre Baskerville";
    }
  }
`

export interface ISignupToNewsletterTile extends IShopTile {}

const SignupToNewsletter = (props: ISignupToNewsletterTile) => {
  const { display, component } = useExitIntent(false)

  const onSubmit = () => {
    display(true)
  }

  return (
    <>
      {component}
      <Tile {...props} width="66%">
        <NewsletterTileContent>
          <h3 className="tile--title">
            Would you like a free creative writing resource?
          </h3>
          <div className="tile--form">
            <Button theme="primary" size="small" onClick={onSubmit}>
              Sign up to our newsletter
            </Button>
          </div>
        </NewsletterTileContent>
      </Tile>
    </>
  )
}

export default SignupToNewsletter
