import React from "react"
import styled from "styled-components"
import LargeTile, { IShopLargeTile } from "./components/LargeTile"
import MediumTile, { IShopMediumTile } from "./components/MediumTile"
import SignupToNewsletter, {
  ISignupToNewsletterTile,
} from "./components/SignUpToNewsletterTile"
import SmallTile, { IShopSmallTile } from "./components/SmallTile"
import { IShopTile } from "./components/Tile"

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    margin-bottom: 20px;
  }
`

export const formatStrapiTile = (element: Strapi.ShopTile) => ({
  id: element.id,
  backgroundImage: element.backgroundImage.localFile,
  image: element.image?.localFile,
  title: element.title,
  bulletItems: element.BulleltItems.map(item => ({
    id: item.id,
    value: item.value,
  })),
  type: element.type,
  button: {
    label: element.button.value,
    target: element.button.targetUrl,
    external: element.button.targetUrl?.charAt(0) !== "/",
  },
})

interface IShopTilesProps {
  tiles: IShopTile[]
}

const ShopTiles = (props: IShopTilesProps) => {
  const { tiles } = props

  const TileTypes = {
    LARGE: "LARGE",
    MEDIUM: "MEDIUM",
    SMALL: "SMALL",
    NEWSLETTER: "NEWSLETTER",
  }

  const getTileType = (tile: IShopTile) => {
    if (tile.type === "newsletter") return TileTypes.NEWSLETTER
    if (tile.bulletItems && tile.bulletItems[0]) return TileTypes.LARGE
    if (tile.title) return TileTypes.MEDIUM
    return TileTypes.SMALL
  }

  return (
    <Wrapper>
      {tiles.map(t => {
        const type = getTileType(t)
        if (type === TileTypes.LARGE)
          return <LargeTile key={t.id} {...(t as IShopLargeTile)} />
        if (type === TileTypes.MEDIUM)
          return <MediumTile key={t.id} {...(t as IShopMediumTile)} />
        if (type === TileTypes.SMALL)
          return <SmallTile key={t.id} {...(t as IShopSmallTile)} />
        if (type === TileTypes.NEWSLETTER)
          return (
            <SignupToNewsletter
              key={t.id}
              {...(t as ISignupToNewsletterTile)}
            />
          )
        return null
      })}
    </Wrapper>
  )
}

export default ShopTiles
