import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Tile, { IShopTile } from "./Tile"

export interface IShopSmallTile extends IShopTile {
  title: undefined
  bulletItems: undefined
}

const SmallTileContent = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  padding: 20px;
  align-items: flex-end;
  justify-content: center;
  .tile--image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
    width: 100%;
  }
`

const SmallTile = (props: IShopSmallTile) => (
  <Tile {...props} width="33%">
    <SmallTileContent>
      <div className="tile--button">
        <Link to={props.button.target}>
          <Button size="regular" theme="primary">
            {props.button.label}
          </Button>
        </Link>
      </div>
      <div className="tile--image">
        {props.image && (
          <GatsbyImage
            image={getGatsbyImage(props.image)}
            alt={props.image.alternativeText || ""}
            objectFit="cover"
          />
        )}
      </div>
    </SmallTileContent>
  </Tile>
)

export default SmallTile
