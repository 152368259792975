import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"

export interface IShopTile {
  id: string
  backgroundImage: any
  image?: any
  type: "newsletter" | "regular" | undefined | null
  title?: string
  bulletItems?: {
    id: string
    value: string
  }[]
  button: {
    label: string
    target: string
    external: boolean
  }
}

const TileWrapper = styled.div`
  position: relative;
  border-radius: 20px;
  min-height: 300px;
  width: ${(props: { width: string }) => {
    if (props.width !== "100%") {
      return `calc(${props.width} - 10px)`
    }
    return `${props.width}`
  }};
  overflow: hidden;
  color: #fff;
  > :first-child {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .tile--image {
    height: 100%;
    > :first-child {
      height: 100%;
    }
  }
  @media (max-width: 1140px) {
    width: ${(props: { width: string }) => {
      if (props.width === "33%") {
        return `calc(50% - 10px)`
      }
      if (props.width === "66%") {
        return `100%`
      }
      if (props.width === "50%") {
        return `100%`
      }
      return `${props.width}`
    }};
  }
  @media (max-width: 650px) {
    width: ${(props: { width: string }) => {
      if (props.width === "33%") {
        return `100%`
      }
    }};
  }
`

export const Tile = (props: any) => (
  <TileWrapper width={props.width}>
    {props.backgroundImage && (
      <GatsbyImage
        image={getGatsbyImage(props.backgroundImage)}
        alt={props.backgroundImage.alternativeText || ""}
        objectFit="cover"
      />
    )}
    {props.children}
  </TileWrapper>
)

export default Tile
