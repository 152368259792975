import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import React from "react"
import ShopHome from "../../components/pages/Shop/ShopHome"
import ShopLayout from "../../layouts/ShopLayout"

const ShopPage = ({ data }: { data: any }) => {
  const location = useLocation()
  return (
    <>
      <ShopLayout
        seoProps={{
          ...(data.strapiShopPage.SEO || {}),
          canonical:
            data.strapiShopPage.SEO?.canonical ||
            `${data.site.siteMetadata.siteUrl}${location.pathname}`,
          url:
            data.strapiShopPage.SEO?.url ||
            `${data.site.siteMetadata.siteUrl}${location.pathname}`,
          image:
            data.strapiShopPage.SEO?.image?.url ||
            "https://cdn.nightzookeeper.com/assets/share-picture.jpg",
        }}
      >
        <ShopHome />
      </ShopLayout>
    </>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiShopPage {
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
    }
  }
`

export default ShopPage
